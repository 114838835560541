@import '../../../styles/baseline';

@mixin elementHover() {
  .hoverParent {
    position: relative;
  }

  .hoverParent:hover.hoverParent_hoverChild__showOverflow,
  .hoverParent:hover .hoverParent_hoverChild__showOverflow {
    overflow: visible;
    width: 32 * $baseline;
  }

  .hoverParent:hover.hoverParent_typography__multiline,
  .hoverParent:hover .hoverParent_typography__multiline {
    text-overflow: unset;
    overflow: visible;
    white-space: normal;
  }

  .hoverParent:hover.hoverParent_hoverChild__hidden,
  .hoverParent:hover .hoverParent_hoverChild__hidden {
    display: block;
  }

  .hoverParent:hover.hoverParent_hoverChild__hidden.element__absolute,
  .hoverParent:hover .hoverParent_hoverChild__hidden.element__absolute {
    position: absolute;
  }

  .hoverParent.hoverParent_hoverChild__hidden,
  .hoverParent .hoverParent_hoverChild__hidden {
    display: none;
  }

  .hoverParent:hover.hoverParent_hoverChild__shown,
  .hoverParent:hover .hoverParent_hoverChild__shown {
    display: none;
  }

  .hoverParent.hoverParent_hoverChild__shown,
  .hoverParent .hoverParent_hoverChild__shown {
    display: block;
  }

  .hoverParent.hoverParent_hoverChild__shown.element__absolute,
  .hoverParent .hoverParent_hoverChild__shown.element__absolute {
    position: absolute;
    display: block;
  }
}
