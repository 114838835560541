@import '../../../styles/colors';
@import '../../../styles/baseline';

.radioButtonGroup {
  &_button {
    position: relative;
    cursor: pointer;

    &__readOnly {
      pointer-events: none;
      cursor: default;
    }
  }

  &_hiddenButton {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
  }

  &_fakeButton {
    width: 3 * $baseline;
    height: 3 * $baseline;

    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid color(black);

    .radioButtonGroup_button__readOnly & {
      border-color: color(black);
      background-color: color(grey20);
    }
  }

  &_fakeButtonIcon {
    height: 2 * $baseline;
    width: 2 * $baseline;
  }

  &_hiddenButton:focus + &_fakeButton {
    outline: 5px solid color(grey20);
    outline: 5px solid -webkit-focus-ring-color;
  }

  &_buttonLabel {
    .radioButtonGroup_button__readOnly & {
      pointer-events: none;
    }
  }
}
