.gutter {
  min-height: 24px;
  min-width: 24px;
}
.gutter__half {
  min-height: 12px;
  min-width: 12px;
}
.gutter__quarter {
  min-height: 6px;
  min-width: 6px;
}

.gutter__sizeZero {
  min-width: 0;
  min-height: 0;
}
@media (min-width: 601px) {
  .gutter__sizeZero {
    min-width: 0;
    min-height: 0;
  }
}
@media (min-width: 841px) {
  .gutter__sizeZero {
    min-width: 0;
    min-height: 0;
  }
}
@media (min-width: 1081px) {
  .gutter__sizeZero {
    min-width: 0;
    min-height: 0;
  }
}
@media (min-width: 1441px) {
  .gutter__sizeZero {
    min-width: 0;
    min-height: 0;
  }
}
@media (min-width: 1921px) {
  .gutter__sizeZero {
    min-width: 0;
    min-height: 0;
  }
}

.gutter__size3xs {
  min-width: 2px;
  min-height: 2px;
}
@media (min-width: 601px) {
  .gutter__size3xs {
    min-width: 2px;
    min-height: 2px;
  }
}
@media (min-width: 841px) {
  .gutter__size3xs {
    min-width: 2px;
    min-height: 2px;
  }
}
@media (min-width: 1081px) {
  .gutter__size3xs {
    min-width: 2px;
    min-height: 2px;
  }
}
@media (min-width: 1441px) {
  .gutter__size3xs {
    min-width: 2px;
    min-height: 2px;
  }
}
@media (min-width: 1921px) {
  .gutter__size3xs {
    min-width: 2px;
    min-height: 2px;
  }
}

.gutter__sizeXxs {
  min-width: 4px;
  min-height: 4px;
}
@media (min-width: 601px) {
  .gutter__sizeXxs {
    min-width: 4px;
    min-height: 4px;
  }
}
@media (min-width: 841px) {
  .gutter__sizeXxs {
    min-width: 4px;
    min-height: 4px;
  }
}
@media (min-width: 1081px) {
  .gutter__sizeXxs {
    min-width: 4px;
    min-height: 4px;
  }
}
@media (min-width: 1441px) {
  .gutter__sizeXxs {
    min-width: 4px;
    min-height: 4px;
  }
}
@media (min-width: 1921px) {
  .gutter__sizeXxs {
    min-width: 4px;
    min-height: 4px;
  }
}

.gutter__sizeXs {
  min-width: 4px;
  min-height: 4px;
}
@media (min-width: 601px) {
  .gutter__sizeXs {
    min-width: 8px;
    min-height: 8px;
  }
}
@media (min-width: 841px) {
  .gutter__sizeXs {
    min-width: 8px;
    min-height: 8px;
  }
}
@media (min-width: 1081px) {
  .gutter__sizeXs {
    min-width: 8px;
    min-height: 8px;
  }
}
@media (min-width: 1441px) {
  .gutter__sizeXs {
    min-width: 8px;
    min-height: 8px;
  }
}
@media (min-width: 1921px) {
  .gutter__sizeXs {
    min-width: 8px;
    min-height: 8px;
  }
}

.gutter__sizeSm {
  min-width: 16px;
  min-height: 16px;
}
@media (min-width: 601px) {
  .gutter__sizeSm {
    min-width: 16px;
    min-height: 16px;
  }
}
@media (min-width: 841px) {
  .gutter__sizeSm {
    min-width: 16px;
    min-height: 16px;
  }
}
@media (min-width: 1081px) {
  .gutter__sizeSm {
    min-width: 16px;
    min-height: 16px;
  }
}
@media (min-width: 1441px) {
  .gutter__sizeSm {
    min-width: 16px;
    min-height: 16px;
  }
}
@media (min-width: 1921px) {
  .gutter__sizeSm {
    min-width: 16px;
    min-height: 16px;
  }
}

.gutter__sizeMd {
  min-width: 8px;
  min-height: 8px;
}
@media (min-width: 601px) {
  .gutter__sizeMd {
    min-width: 8px;
    min-height: 8px;
  }
}
@media (min-width: 841px) {
  .gutter__sizeMd {
    min-width: 16px;
    min-height: 16px;
  }
}
@media (min-width: 1081px) {
  .gutter__sizeMd {
    min-width: 24px;
    min-height: 24px;
  }
}
@media (min-width: 1441px) {
  .gutter__sizeMd {
    min-width: 24px;
    min-height: 24px;
  }
}
@media (min-width: 1921px) {
  .gutter__sizeMd {
    min-width: 24px;
    min-height: 24px;
  }
}

.gutter__sizeLg {
  min-width: 16px;
  min-height: 16px;
}
@media (min-width: 601px) {
  .gutter__sizeLg {
    min-width: 16px;
    min-height: 16px;
  }
}
@media (min-width: 841px) {
  .gutter__sizeLg {
    min-width: 24px;
    min-height: 24px;
  }
}
@media (min-width: 1081px) {
  .gutter__sizeLg {
    min-width: 32px;
    min-height: 32px;
  }
}
@media (min-width: 1441px) {
  .gutter__sizeLg {
    min-width: 32px;
    min-height: 32px;
  }
}
@media (min-width: 1921px) {
  .gutter__sizeLg {
    min-width: 32px;
    min-height: 32px;
  }
}

.gutter__sizeXlg {
  min-width: 48px;
  min-height: 48px;
}
@media (min-width: 601px) {
  .gutter__sizeXlg {
    min-width: 48px;
    min-height: 48px;
  }
}
@media (min-width: 841px) {
  .gutter__sizeXlg {
    min-width: 48px;
    min-height: 48px;
  }
}
@media (min-width: 1081px) {
  .gutter__sizeXlg {
    min-width: 48px;
    min-height: 48px;
  }
}
@media (min-width: 1441px) {
  .gutter__sizeXlg {
    min-width: 48px;
    min-height: 48px;
  }
}
@media (min-width: 1921px) {
  .gutter__sizeXlg {
    min-width: 48px;
    min-height: 48px;
  }
}

.gutter__sizeXxlg {
  min-width: 24px;
  min-height: 24px;
}
@media (min-width: 601px) {
  .gutter__sizeXxlg {
    min-width: 32px;
    min-height: 32px;
  }
}
@media (min-width: 841px) {
  .gutter__sizeXxlg {
    min-width: 48px;
    min-height: 48px;
  }
}
@media (min-width: 1081px) {
  .gutter__sizeXxlg {
    min-width: 96px;
    min-height: 96px;
  }
}
@media (min-width: 1441px) {
  .gutter__sizeXxlg {
    min-width: 96px;
    min-height: 96px;
  }
}
@media (min-width: 1921px) {
  .gutter__sizeXxlg {
    min-width: 96px;
    min-height: 96px;
  }
}