@import '../../../../node_modules/shared-between-front-ends/src/styles/colors';

.scheduler {
  &_cell {
    border: 1px solid color(tableBorder);
  }

  &_sticky {
    position: sticky;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
  }
}
