.scheduler_cell {
  border: 1px solid #b4b4b4;
}
.scheduler_sticky {
  position: sticky;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
}