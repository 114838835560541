@import '../../../styles/baseline';
@import '../../../styles/capitalize';
@import '../../../styles/spacing';

$heights: (
  3xs: 2 * $baseline,
  xxs: 4 * $baseline,
  xs: 8 * $baseline,
  sm: 16 * $baseline,
  md: 32 * $baseline,
  lg: 64 * $baseline,
  xlg: 128 * $baseline,
);

@mixin elementHeights() {
  @each $heightName, $heightValue in $heights {
    &__height#{capitalize('#{$heightName}')} {
      height: $heightValue;
    }

    &__minHeight#{capitalize('#{$heightName}')} {
      min-height: $heightValue;
    }

    &__maxHeight#{capitalize('#{$heightName}')} {
      max-height: $heightValue;
    }
  }

  @each $heightName, $heightValue in $heights {
    &__height#{capitalize('#{$heightName}OrViewport')} {
      height: calc(min(100vh - #{8 * $baseline}, #{$heightValue}));
    }

    &__maxHeight#{capitalize('#{$heightName}OrViewport')} {
      max-height: calc(min(100vh - #{8 * $baseline}, #{$heightValue}));
    }
  }
}
