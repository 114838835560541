@import 'baseline';
@import 'breakpoints';
@import 'capitalize';

$spacingMinusSm: -2 * $baseline;
$spacingZero: 0;
$spacing3xs: 0.5 * $typographyBaseline;
$spacingXxs: 1 * $typographyBaseline;
$spacingXs: 1 * $baseline;
$spacingSm: 2 * $baseline;
$spacingMd: 3 * $baseline;
$spacingLg: 4 * $baseline;
$spacingXlg: 6 * $baseline;
$spacingXxlg: 12 * $baseline;

$spacings: (
  minusSm: $spacingMinusSm,
  zero: $spacingZero,
  3xs: $spacing3xs,
  xxs: $spacingXxs,
  xs: $spacingXs,
  sm: $spacingSm,
  md: $spacingMd,
  lg: $spacingLg,
  xlg: $spacingXlg,
  xxlg: $spacingXxlg,
);

$responsiveSpacingsForMinusSm: (
  $spacingMinusSm,
  $spacingMinusSm,
  $spacingMinusSm,
  $spacingMinusSm,
  $spacingMinusSm,
  $spacingMinusSm
);

$responsiveSpacingsForZero: (
  $spacingZero,
  $spacingZero,
  $spacingZero,
  $spacingZero,
  $spacingZero,
  $spacingZero
);

$responsiveSpacingsFor3xs: (
  $spacing3xs,
  $spacing3xs,
  $spacing3xs,
  $spacing3xs,
  $spacing3xs,
  $spacing3xs
);

$responsiveSpacingsForXxs: (
  $spacingXxs,
  $spacingXxs,
  $spacingXxs,
  $spacingXxs,
  $spacingXxs,
  $spacingXxs
);

$responsiveSpacingsForXs: (
  $spacingXxs,
  $spacingXs,
  $spacingXs,
  $spacingXs,
  $spacingXs,
  $spacingXs
);

$responsiveSpacingsForSm: (
  $spacingSm,
  $spacingSm,
  $spacingSm,
  $spacingSm,
  $spacingSm,
  $spacingSm
);

$responsiveSpacingsForMd: (
  $spacingXs,
  $spacingXs,
  $spacingSm,
  $spacingMd,
  $spacingMd,
  $spacingMd
);

$responsiveSpacingsForLg: (
  $spacingSm,
  $spacingSm,
  $spacingMd,
  $spacingLg,
  $spacingLg,
  $spacingLg
);

$responsiveSpacingsForXlg: (
  $spacingXlg,
  $spacingXlg,
  $spacingXlg,
  $spacingXlg,
  $spacingXlg,
  $spacingXlg
);

$responsiveSpacingsForXxlg: (
  $spacingMd,
  $spacingLg,
  $spacingXlg,
  $spacingXxlg,
  $spacingXxlg,
  $spacingXxlg
);

@mixin spacing(
  $spacingType,
  $sizeCategory,
  $direction,
  $xsSize,
  $smSize,
  $mdSize,
  $lgSize,
  $xlgSize,
  $xxlgSize
) {
  .#{$spacingType}__size#{capitalize($sizeCategory)}#{capitalize($direction)} {
    #{$spacingType}-#{$direction}: $xsSize;

    @include from('sm') {
      #{$spacingType}-#{$direction}: $smSize;
    }

    @include from('md') {
      #{$spacingType}-#{$direction}: $mdSize;
    }

    @include from('lg') {
      #{$spacingType}-#{$direction}: $lgSize;
    }

    @include from('xlg') {
      #{$spacingType}-#{$direction}: $xlgSize;
    }

    @include from('xxlg') {
      #{$spacingType}-#{$direction}: $xxlgSize;
    }
  }
}

@mixin spacings(
  $spacingType,
  $sizeCategory,
  $xsSize,
  $smSize,
  $mdSize,
  $lgSize,
  $xlgSize,
  $xxlgSize
) {
  // prettier-ignore
  @include spacing($spacingType, $sizeCategory, 'left', $xsSize, $smSize, $mdSize, $lgSize, $xlgSize, $xxlgSize);

  // prettier-ignore
  @include spacing($spacingType, $sizeCategory, 'right', $xsSize, $smSize, $mdSize, $lgSize, $xlgSize, $xxlgSize);

  // prettier-ignore
  @include spacing($spacingType, $sizeCategory, 'top', $xsSize, $smSize, $mdSize, $lgSize, $xlgSize, $xxlgSize);

  // prettier-ignore
  @include spacing($spacingType, $sizeCategory, 'bottom', $xsSize, $smSize, $mdSize, $lgSize, $xlgSize, $xxlgSize);
}
