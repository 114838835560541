@import '../../../styles/baseline';

.icon {
  line-height: 0;

  &__sizeXs {
    font-size: 1 * $baseline;
  }

  &__sizeSm {
    font-size: 2 * $baseline;
  }

  &__sizeMd {
    font-size: 3 * $baseline;
  }

  &__sizeLg {
    font-size: 4 * $baseline;
  }
}
