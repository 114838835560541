.typography__primary {
  font-family: "Barlow", Arial, sans-serif;
}
.typography__uppercase {
  text-transform: uppercase;
}
.typography__strikeThrough {
  text-decoration: line-through;
}
.typography__singleLine {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.typography__noLineHeight {
  line-height: unset;
}
.typography__paragraph {
  max-width: 640px;
}

.typography__sizeLg {
  font-size: 40px;
  line-height: 48px;
}
@media (min-width: 481px) {
  .typography__sizeLg {
    font-size: 40px;
    line-height: 48px;
  }
}

.typography__sizeMd {
  font-size: 32px;
  line-height: 40px;
}
@media (min-width: 481px) {
  .typography__sizeMd {
    font-size: 32px;
    line-height: 40px;
  }
}

.typography__sizeSm {
  font-size: 24px;
  line-height: 32px;
}
@media (min-width: 481px) {
  .typography__sizeSm {
    font-size: 24px;
    line-height: 32px;
  }
}

.typography__sizeXs {
  font-size: 20px;
  line-height: 28px;
}
@media (min-width: 481px) {
  .typography__sizeXs {
    font-size: 20px;
    line-height: 28px;
  }
}

.typography__sizeXxs {
  font-size: 16px;
  line-height: 24px;
}
@media (min-width: 481px) {
  .typography__sizeXxs {
    font-size: 16px;
    line-height: 24px;
  }
}

.typography__size3xs {
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 481px) {
  .typography__size3xs {
    font-size: 14px;
    line-height: 20px;
  }
}

.typography__size4xs {
  font-size: 12px;
  line-height: 20px;
}
@media (min-width: 481px) {
  .typography__size4xs {
    font-size: 12px;
    line-height: 20px;
  }
}