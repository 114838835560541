.tileBag_tile {
  position: relative;
  min-width: 0;
}
.tileBag_tabletChunk {
  min-width: 0;
}
@media (min-width: 601px) {
  .tileBag_tile {
    flex-basis: 50%;
    width: 50%;
  }
  .tileBag_tabletChunk {
    display: flex;
    flex-basis: 50%;
    width: 100%;
  }
}
@media (min-width: 1081px) {
  .tileBag_tabletChunk {
    width: 50%;
  }
}
@media (min-width: 1081px) {
  .tileBag_desktopChunk {
    display: flex;
  }
}