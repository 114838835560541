.separatorLine {
  &__vertical {
    align-self: stretch;
    min-width: 1px;
  }

  &__horizontal {
    min-height: 1px;
  }
}
