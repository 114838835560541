.padding__sizeMinusSmLeft {
  padding-left: -16px;
}
@media (min-width: 601px) {
  .padding__sizeMinusSmLeft {
    padding-left: -16px;
  }
}
@media (min-width: 841px) {
  .padding__sizeMinusSmLeft {
    padding-left: -16px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeMinusSmLeft {
    padding-left: -16px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeMinusSmLeft {
    padding-left: -16px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeMinusSmLeft {
    padding-left: -16px;
  }
}

.padding__sizeMinusSmRight {
  padding-right: -16px;
}
@media (min-width: 601px) {
  .padding__sizeMinusSmRight {
    padding-right: -16px;
  }
}
@media (min-width: 841px) {
  .padding__sizeMinusSmRight {
    padding-right: -16px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeMinusSmRight {
    padding-right: -16px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeMinusSmRight {
    padding-right: -16px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeMinusSmRight {
    padding-right: -16px;
  }
}

.padding__sizeMinusSmTop {
  padding-top: -16px;
}
@media (min-width: 601px) {
  .padding__sizeMinusSmTop {
    padding-top: -16px;
  }
}
@media (min-width: 841px) {
  .padding__sizeMinusSmTop {
    padding-top: -16px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeMinusSmTop {
    padding-top: -16px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeMinusSmTop {
    padding-top: -16px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeMinusSmTop {
    padding-top: -16px;
  }
}

.padding__sizeMinusSmBottom {
  padding-bottom: -16px;
}
@media (min-width: 601px) {
  .padding__sizeMinusSmBottom {
    padding-bottom: -16px;
  }
}
@media (min-width: 841px) {
  .padding__sizeMinusSmBottom {
    padding-bottom: -16px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeMinusSmBottom {
    padding-bottom: -16px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeMinusSmBottom {
    padding-bottom: -16px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeMinusSmBottom {
    padding-bottom: -16px;
  }
}

.padding__sizeZeroLeft {
  padding-left: 0;
}
@media (min-width: 601px) {
  .padding__sizeZeroLeft {
    padding-left: 0;
  }
}
@media (min-width: 841px) {
  .padding__sizeZeroLeft {
    padding-left: 0;
  }
}
@media (min-width: 1081px) {
  .padding__sizeZeroLeft {
    padding-left: 0;
  }
}
@media (min-width: 1441px) {
  .padding__sizeZeroLeft {
    padding-left: 0;
  }
}
@media (min-width: 1921px) {
  .padding__sizeZeroLeft {
    padding-left: 0;
  }
}

.padding__sizeZeroRight {
  padding-right: 0;
}
@media (min-width: 601px) {
  .padding__sizeZeroRight {
    padding-right: 0;
  }
}
@media (min-width: 841px) {
  .padding__sizeZeroRight {
    padding-right: 0;
  }
}
@media (min-width: 1081px) {
  .padding__sizeZeroRight {
    padding-right: 0;
  }
}
@media (min-width: 1441px) {
  .padding__sizeZeroRight {
    padding-right: 0;
  }
}
@media (min-width: 1921px) {
  .padding__sizeZeroRight {
    padding-right: 0;
  }
}

.padding__sizeZeroTop {
  padding-top: 0;
}
@media (min-width: 601px) {
  .padding__sizeZeroTop {
    padding-top: 0;
  }
}
@media (min-width: 841px) {
  .padding__sizeZeroTop {
    padding-top: 0;
  }
}
@media (min-width: 1081px) {
  .padding__sizeZeroTop {
    padding-top: 0;
  }
}
@media (min-width: 1441px) {
  .padding__sizeZeroTop {
    padding-top: 0;
  }
}
@media (min-width: 1921px) {
  .padding__sizeZeroTop {
    padding-top: 0;
  }
}

.padding__sizeZeroBottom {
  padding-bottom: 0;
}
@media (min-width: 601px) {
  .padding__sizeZeroBottom {
    padding-bottom: 0;
  }
}
@media (min-width: 841px) {
  .padding__sizeZeroBottom {
    padding-bottom: 0;
  }
}
@media (min-width: 1081px) {
  .padding__sizeZeroBottom {
    padding-bottom: 0;
  }
}
@media (min-width: 1441px) {
  .padding__sizeZeroBottom {
    padding-bottom: 0;
  }
}
@media (min-width: 1921px) {
  .padding__sizeZeroBottom {
    padding-bottom: 0;
  }
}

.padding__size3xsLeft {
  padding-left: 2px;
}
@media (min-width: 601px) {
  .padding__size3xsLeft {
    padding-left: 2px;
  }
}
@media (min-width: 841px) {
  .padding__size3xsLeft {
    padding-left: 2px;
  }
}
@media (min-width: 1081px) {
  .padding__size3xsLeft {
    padding-left: 2px;
  }
}
@media (min-width: 1441px) {
  .padding__size3xsLeft {
    padding-left: 2px;
  }
}
@media (min-width: 1921px) {
  .padding__size3xsLeft {
    padding-left: 2px;
  }
}

.padding__size3xsRight {
  padding-right: 2px;
}
@media (min-width: 601px) {
  .padding__size3xsRight {
    padding-right: 2px;
  }
}
@media (min-width: 841px) {
  .padding__size3xsRight {
    padding-right: 2px;
  }
}
@media (min-width: 1081px) {
  .padding__size3xsRight {
    padding-right: 2px;
  }
}
@media (min-width: 1441px) {
  .padding__size3xsRight {
    padding-right: 2px;
  }
}
@media (min-width: 1921px) {
  .padding__size3xsRight {
    padding-right: 2px;
  }
}

.padding__size3xsTop {
  padding-top: 2px;
}
@media (min-width: 601px) {
  .padding__size3xsTop {
    padding-top: 2px;
  }
}
@media (min-width: 841px) {
  .padding__size3xsTop {
    padding-top: 2px;
  }
}
@media (min-width: 1081px) {
  .padding__size3xsTop {
    padding-top: 2px;
  }
}
@media (min-width: 1441px) {
  .padding__size3xsTop {
    padding-top: 2px;
  }
}
@media (min-width: 1921px) {
  .padding__size3xsTop {
    padding-top: 2px;
  }
}

.padding__size3xsBottom {
  padding-bottom: 2px;
}
@media (min-width: 601px) {
  .padding__size3xsBottom {
    padding-bottom: 2px;
  }
}
@media (min-width: 841px) {
  .padding__size3xsBottom {
    padding-bottom: 2px;
  }
}
@media (min-width: 1081px) {
  .padding__size3xsBottom {
    padding-bottom: 2px;
  }
}
@media (min-width: 1441px) {
  .padding__size3xsBottom {
    padding-bottom: 2px;
  }
}
@media (min-width: 1921px) {
  .padding__size3xsBottom {
    padding-bottom: 2px;
  }
}

.padding__sizeXxsLeft {
  padding-left: 4px;
}
@media (min-width: 601px) {
  .padding__sizeXxsLeft {
    padding-left: 4px;
  }
}
@media (min-width: 841px) {
  .padding__sizeXxsLeft {
    padding-left: 4px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeXxsLeft {
    padding-left: 4px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeXxsLeft {
    padding-left: 4px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeXxsLeft {
    padding-left: 4px;
  }
}

.padding__sizeXxsRight {
  padding-right: 4px;
}
@media (min-width: 601px) {
  .padding__sizeXxsRight {
    padding-right: 4px;
  }
}
@media (min-width: 841px) {
  .padding__sizeXxsRight {
    padding-right: 4px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeXxsRight {
    padding-right: 4px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeXxsRight {
    padding-right: 4px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeXxsRight {
    padding-right: 4px;
  }
}

.padding__sizeXxsTop {
  padding-top: 4px;
}
@media (min-width: 601px) {
  .padding__sizeXxsTop {
    padding-top: 4px;
  }
}
@media (min-width: 841px) {
  .padding__sizeXxsTop {
    padding-top: 4px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeXxsTop {
    padding-top: 4px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeXxsTop {
    padding-top: 4px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeXxsTop {
    padding-top: 4px;
  }
}

.padding__sizeXxsBottom {
  padding-bottom: 4px;
}
@media (min-width: 601px) {
  .padding__sizeXxsBottom {
    padding-bottom: 4px;
  }
}
@media (min-width: 841px) {
  .padding__sizeXxsBottom {
    padding-bottom: 4px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeXxsBottom {
    padding-bottom: 4px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeXxsBottom {
    padding-bottom: 4px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeXxsBottom {
    padding-bottom: 4px;
  }
}

.padding__sizeXsLeft {
  padding-left: 4px;
}
@media (min-width: 601px) {
  .padding__sizeXsLeft {
    padding-left: 8px;
  }
}
@media (min-width: 841px) {
  .padding__sizeXsLeft {
    padding-left: 8px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeXsLeft {
    padding-left: 8px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeXsLeft {
    padding-left: 8px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeXsLeft {
    padding-left: 8px;
  }
}

.padding__sizeXsRight {
  padding-right: 4px;
}
@media (min-width: 601px) {
  .padding__sizeXsRight {
    padding-right: 8px;
  }
}
@media (min-width: 841px) {
  .padding__sizeXsRight {
    padding-right: 8px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeXsRight {
    padding-right: 8px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeXsRight {
    padding-right: 8px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeXsRight {
    padding-right: 8px;
  }
}

.padding__sizeXsTop {
  padding-top: 4px;
}
@media (min-width: 601px) {
  .padding__sizeXsTop {
    padding-top: 8px;
  }
}
@media (min-width: 841px) {
  .padding__sizeXsTop {
    padding-top: 8px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeXsTop {
    padding-top: 8px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeXsTop {
    padding-top: 8px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeXsTop {
    padding-top: 8px;
  }
}

.padding__sizeXsBottom {
  padding-bottom: 4px;
}
@media (min-width: 601px) {
  .padding__sizeXsBottom {
    padding-bottom: 8px;
  }
}
@media (min-width: 841px) {
  .padding__sizeXsBottom {
    padding-bottom: 8px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeXsBottom {
    padding-bottom: 8px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeXsBottom {
    padding-bottom: 8px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeXsBottom {
    padding-bottom: 8px;
  }
}

.padding__sizeSmLeft {
  padding-left: 16px;
}
@media (min-width: 601px) {
  .padding__sizeSmLeft {
    padding-left: 16px;
  }
}
@media (min-width: 841px) {
  .padding__sizeSmLeft {
    padding-left: 16px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeSmLeft {
    padding-left: 16px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeSmLeft {
    padding-left: 16px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeSmLeft {
    padding-left: 16px;
  }
}

.padding__sizeSmRight {
  padding-right: 16px;
}
@media (min-width: 601px) {
  .padding__sizeSmRight {
    padding-right: 16px;
  }
}
@media (min-width: 841px) {
  .padding__sizeSmRight {
    padding-right: 16px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeSmRight {
    padding-right: 16px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeSmRight {
    padding-right: 16px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeSmRight {
    padding-right: 16px;
  }
}

.padding__sizeSmTop {
  padding-top: 16px;
}
@media (min-width: 601px) {
  .padding__sizeSmTop {
    padding-top: 16px;
  }
}
@media (min-width: 841px) {
  .padding__sizeSmTop {
    padding-top: 16px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeSmTop {
    padding-top: 16px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeSmTop {
    padding-top: 16px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeSmTop {
    padding-top: 16px;
  }
}

.padding__sizeSmBottom {
  padding-bottom: 16px;
}
@media (min-width: 601px) {
  .padding__sizeSmBottom {
    padding-bottom: 16px;
  }
}
@media (min-width: 841px) {
  .padding__sizeSmBottom {
    padding-bottom: 16px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeSmBottom {
    padding-bottom: 16px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeSmBottom {
    padding-bottom: 16px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeSmBottom {
    padding-bottom: 16px;
  }
}

.padding__sizeMdLeft {
  padding-left: 8px;
}
@media (min-width: 601px) {
  .padding__sizeMdLeft {
    padding-left: 8px;
  }
}
@media (min-width: 841px) {
  .padding__sizeMdLeft {
    padding-left: 16px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeMdLeft {
    padding-left: 24px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeMdLeft {
    padding-left: 24px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeMdLeft {
    padding-left: 24px;
  }
}

.padding__sizeMdRight {
  padding-right: 8px;
}
@media (min-width: 601px) {
  .padding__sizeMdRight {
    padding-right: 8px;
  }
}
@media (min-width: 841px) {
  .padding__sizeMdRight {
    padding-right: 16px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeMdRight {
    padding-right: 24px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeMdRight {
    padding-right: 24px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeMdRight {
    padding-right: 24px;
  }
}

.padding__sizeMdTop {
  padding-top: 8px;
}
@media (min-width: 601px) {
  .padding__sizeMdTop {
    padding-top: 8px;
  }
}
@media (min-width: 841px) {
  .padding__sizeMdTop {
    padding-top: 16px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeMdTop {
    padding-top: 24px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeMdTop {
    padding-top: 24px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeMdTop {
    padding-top: 24px;
  }
}

.padding__sizeMdBottom {
  padding-bottom: 8px;
}
@media (min-width: 601px) {
  .padding__sizeMdBottom {
    padding-bottom: 8px;
  }
}
@media (min-width: 841px) {
  .padding__sizeMdBottom {
    padding-bottom: 16px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeMdBottom {
    padding-bottom: 24px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeMdBottom {
    padding-bottom: 24px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeMdBottom {
    padding-bottom: 24px;
  }
}

.padding__sizeLgLeft {
  padding-left: 16px;
}
@media (min-width: 601px) {
  .padding__sizeLgLeft {
    padding-left: 16px;
  }
}
@media (min-width: 841px) {
  .padding__sizeLgLeft {
    padding-left: 24px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeLgLeft {
    padding-left: 32px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeLgLeft {
    padding-left: 32px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeLgLeft {
    padding-left: 32px;
  }
}

.padding__sizeLgRight {
  padding-right: 16px;
}
@media (min-width: 601px) {
  .padding__sizeLgRight {
    padding-right: 16px;
  }
}
@media (min-width: 841px) {
  .padding__sizeLgRight {
    padding-right: 24px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeLgRight {
    padding-right: 32px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeLgRight {
    padding-right: 32px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeLgRight {
    padding-right: 32px;
  }
}

.padding__sizeLgTop {
  padding-top: 16px;
}
@media (min-width: 601px) {
  .padding__sizeLgTop {
    padding-top: 16px;
  }
}
@media (min-width: 841px) {
  .padding__sizeLgTop {
    padding-top: 24px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeLgTop {
    padding-top: 32px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeLgTop {
    padding-top: 32px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeLgTop {
    padding-top: 32px;
  }
}

.padding__sizeLgBottom {
  padding-bottom: 16px;
}
@media (min-width: 601px) {
  .padding__sizeLgBottom {
    padding-bottom: 16px;
  }
}
@media (min-width: 841px) {
  .padding__sizeLgBottom {
    padding-bottom: 24px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeLgBottom {
    padding-bottom: 32px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeLgBottom {
    padding-bottom: 32px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeLgBottom {
    padding-bottom: 32px;
  }
}

.padding__sizeXlgLeft {
  padding-left: 48px;
}
@media (min-width: 601px) {
  .padding__sizeXlgLeft {
    padding-left: 48px;
  }
}
@media (min-width: 841px) {
  .padding__sizeXlgLeft {
    padding-left: 48px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeXlgLeft {
    padding-left: 48px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeXlgLeft {
    padding-left: 48px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeXlgLeft {
    padding-left: 48px;
  }
}

.padding__sizeXlgRight {
  padding-right: 48px;
}
@media (min-width: 601px) {
  .padding__sizeXlgRight {
    padding-right: 48px;
  }
}
@media (min-width: 841px) {
  .padding__sizeXlgRight {
    padding-right: 48px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeXlgRight {
    padding-right: 48px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeXlgRight {
    padding-right: 48px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeXlgRight {
    padding-right: 48px;
  }
}

.padding__sizeXlgTop {
  padding-top: 48px;
}
@media (min-width: 601px) {
  .padding__sizeXlgTop {
    padding-top: 48px;
  }
}
@media (min-width: 841px) {
  .padding__sizeXlgTop {
    padding-top: 48px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeXlgTop {
    padding-top: 48px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeXlgTop {
    padding-top: 48px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeXlgTop {
    padding-top: 48px;
  }
}

.padding__sizeXlgBottom {
  padding-bottom: 48px;
}
@media (min-width: 601px) {
  .padding__sizeXlgBottom {
    padding-bottom: 48px;
  }
}
@media (min-width: 841px) {
  .padding__sizeXlgBottom {
    padding-bottom: 48px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeXlgBottom {
    padding-bottom: 48px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeXlgBottom {
    padding-bottom: 48px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeXlgBottom {
    padding-bottom: 48px;
  }
}

.padding__sizeXxlgLeft {
  padding-left: 24px;
}
@media (min-width: 601px) {
  .padding__sizeXxlgLeft {
    padding-left: 32px;
  }
}
@media (min-width: 841px) {
  .padding__sizeXxlgLeft {
    padding-left: 48px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeXxlgLeft {
    padding-left: 96px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeXxlgLeft {
    padding-left: 96px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeXxlgLeft {
    padding-left: 96px;
  }
}

.padding__sizeXxlgRight {
  padding-right: 24px;
}
@media (min-width: 601px) {
  .padding__sizeXxlgRight {
    padding-right: 32px;
  }
}
@media (min-width: 841px) {
  .padding__sizeXxlgRight {
    padding-right: 48px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeXxlgRight {
    padding-right: 96px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeXxlgRight {
    padding-right: 96px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeXxlgRight {
    padding-right: 96px;
  }
}

.padding__sizeXxlgTop {
  padding-top: 24px;
}
@media (min-width: 601px) {
  .padding__sizeXxlgTop {
    padding-top: 32px;
  }
}
@media (min-width: 841px) {
  .padding__sizeXxlgTop {
    padding-top: 48px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeXxlgTop {
    padding-top: 96px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeXxlgTop {
    padding-top: 96px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeXxlgTop {
    padding-top: 96px;
  }
}

.padding__sizeXxlgBottom {
  padding-bottom: 24px;
}
@media (min-width: 601px) {
  .padding__sizeXxlgBottom {
    padding-bottom: 32px;
  }
}
@media (min-width: 841px) {
  .padding__sizeXxlgBottom {
    padding-bottom: 48px;
  }
}
@media (min-width: 1081px) {
  .padding__sizeXxlgBottom {
    padding-bottom: 96px;
  }
}
@media (min-width: 1441px) {
  .padding__sizeXxlgBottom {
    padding-bottom: 96px;
  }
}
@media (min-width: 1921px) {
  .padding__sizeXxlgBottom {
    padding-bottom: 96px;
  }
}