.element_borderRadius__topLeftZero {
  border-top-left-radius: 0;
}

.element_borderRadius__topLeftXs {
  border-top-left-radius: 2px;
}

.element_borderRadius__topLeftSm {
  border-top-left-radius: 4px;
}

.element_borderRadius__topLeftMd {
  border-top-left-radius: 8px;
}

.element_borderRadius__topLeftLg {
  border-top-left-radius: 12px;
}

.element_borderRadius__topLeftRounded {
  border-top-left-radius: 999px;
}

.element_borderRadius__topLeftElliptical {
  border-top-left-radius: 50%;
}

.element_borderRadius__topRightZero {
  border-top-right-radius: 0;
}

.element_borderRadius__topRightXs {
  border-top-right-radius: 2px;
}

.element_borderRadius__topRightSm {
  border-top-right-radius: 4px;
}

.element_borderRadius__topRightMd {
  border-top-right-radius: 8px;
}

.element_borderRadius__topRightLg {
  border-top-right-radius: 12px;
}

.element_borderRadius__topRightRounded {
  border-top-right-radius: 999px;
}

.element_borderRadius__topRightElliptical {
  border-top-right-radius: 50%;
}

.element_borderRadius__bottomRightZero {
  border-bottom-right-radius: 0;
}

.element_borderRadius__bottomRightXs {
  border-bottom-right-radius: 2px;
}

.element_borderRadius__bottomRightSm {
  border-bottom-right-radius: 4px;
}

.element_borderRadius__bottomRightMd {
  border-bottom-right-radius: 8px;
}

.element_borderRadius__bottomRightLg {
  border-bottom-right-radius: 12px;
}

.element_borderRadius__bottomRightRounded {
  border-bottom-right-radius: 999px;
}

.element_borderRadius__bottomRightElliptical {
  border-bottom-right-radius: 50%;
}

.element_borderRadius__bottomLeftZero {
  border-bottom-left-radius: 0;
}

.element_borderRadius__bottomLeftXs {
  border-bottom-left-radius: 2px;
}

.element_borderRadius__bottomLeftSm {
  border-bottom-left-radius: 4px;
}

.element_borderRadius__bottomLeftMd {
  border-bottom-left-radius: 8px;
}

.element_borderRadius__bottomLeftLg {
  border-bottom-left-radius: 12px;
}

.element_borderRadius__bottomLeftRounded {
  border-bottom-left-radius: 999px;
}

.element_borderRadius__bottomLeftElliptical {
  border-bottom-left-radius: 50%;
}

.element {
  margin: 0;
  padding: 0;
  border: 0;
  max-width: none;
  box-sizing: border-box;
  font-weight: normal;
}
.element__striped:nth-child(odd) {
  background-color: rgba(245, 245, 245, 0.8);
}
.element__stickyTop {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}
.element__stickyBottom {
  position: sticky;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.element__draggable {
  cursor: grab;
}
.element__draggable:active {
  cursor: grabbing;
}
.element__hidden {
  display: none !important;
}
.element__occupyContentSpaceButHide {
  visibility: hidden;
}
.element__hideOverflow {
  overflow: hidden;
}
.element__showOverflow {
  overflow: visible;
}
.element__scrollOverflow {
  overflow-y: scroll;
}
.element__forceWrap {
  overflow: hidden;
  word-wrap: break-word;
  display: inline-block;
  max-width: 100%;
}
.element__bold {
  font-weight: bold;
}
.element__inlineBlock {
  display: inline-block;
}
.element__block {
  display: block;
}
.element__alignSelfCenter {
  align-self: center;
}
.element__boxShadow {
  box-shadow: 6px 6px 6px -6px rgba(0, 0, 0, 0.1);
}
.element__boxShadowSizeLg {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
.element__occupyVerticalSpace {
  height: 100%;
}
.element__occupyHorizontalSpace {
  width: 100%;
}
.element__alignTextToCenter {
  text-align: center;
}
.element__alignTextToLeft {
  text-align: left;
}
.element__alignTextToRight {
  text-align: right;
}
.element__alignSelfToBottom {
  align-self: flex-end;
}
.element__clickableAppearance {
  cursor: pointer;
}
.element__nonClickableAppearance {
  cursor: default;
}
.element__relative {
  position: relative;
}
.element__bringForward {
  z-index: 1;
}
.element__pushBehind {
  z-index: -1;
}
.element__absolute {
  position: absolute;
}
.element__absolutePositionMyDimensionsToMatchParents {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.element__absolutePositionMyTopRightToTopRightOfParent {
  top: 0;
  right: 0;
}
.element__absolutePositionMyTopRightToTopLeftOfParent {
  top: 0;
  right: 100%;
}
.element__absolutePositionMyTopLeftToTopRightOfParent {
  top: 0;
  left: 100%;
}
.element__absolutePositionMyCenterLeftToCenterLeftOfParent {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.element__absolutePositionMyCenterRightToCenterRightOfParent {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.element__absolutePositionMyBottomRightToTopLeftOfParentWithSmOffset {
  bottom: calc(100% - 16px);
  left: calc(100% - 16px);
}
.element__absolutePositionMyBottomLeftToTopRightOfParentWithSmOffset {
  bottom: calc(100% - 16px);
  right: calc(100% - 16px);
}
.element__highlight {
  position: relative;
}
.element__highlight::after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  background-color: rgba(248, 152, 56, 0.25);
}
.element__border {
  border-style: solid;
}
.element__borderSizeZero {
  border-width: 0;
}
.element__borderSizeSm {
  border-width: 1px;
}
.element__borderSizeMd {
  border-width: 2px;
}
.element__borderSizeLg {
  border-width: 3px;
}
.element__borderSizeXlg {
  border-width: 4px;
}
.element__borderColorPrimary {
  border-color: #243e90;
}
.element__borderColorSecondary {
  border-color: darkGreen;
}
.element__borderColorLightSecondary {
  border-color: rgba(178, 136, 19, 0.1);
}
.element__borderColorError {
  border-color: red;
}
.element__borderColorLightError {
  border-color: rgba(162, 42, 21, 0.1);
}
.element__borderColorDanger {
  border-color: red;
}
.element__borderColorSuccess {
  border-color: green;
}
.element__borderColorLightGrey {
  border-color: #f7f7f7;
}
.element__borderColorGrey1 {
  border-color: #f7f7f7;
}
.element__borderColorGrey2 {
  border-color: #efefef;
}
.element__borderColorGrey3 {
  border-color: #e7e7e7;
}
.element__borderColorGrey4 {
  border-color: #e1e1e1;
}
.element__borderColorGrey5 {
  border-color: #dbdbdb;
}
.element__borderColorGrey6 {
  border-color: #808080;
}
.element__borderColorEltelGrey {
  border-color: #6c6c6c;
}
.element__borderColorDoneBackground {
  border-color: #c0cbc0;
}
.element__borderColorUrgentBackground {
  border-color: #e79791;
}
.element__borderColorShroud {
  border-color: rgba(0, 0, 0, 0.5);
}
.element__borderColorHighlight {
  border-color: rgba(248, 152, 56, 0.25);
}
.element__borderColorFontColor {
  border-color: #333333;
}
.element__borderColorLightSuccess {
  border-color: #ecf7ec;
}
.element__borderColorAction {
  border-color: #f89838;
}
.element__borderColorActionHover {
  border-color: #fab269;
}
.element__borderColorIndicationGrey {
  border-color: #cecece;
}
.element__borderColorTableBorder {
  border-color: #b4b4b4;
}
.element__borderColorInputBorder {
  border-color: rgba(108, 108, 108, 0.3);
}
.element__borderColorDisabled {
  border-color: #e8e8e4;
}
.element__borderColorDisabledText {
  border-color: #c2c2c2;
}
.element__borderColorPrimaryAccent {
  border-color: rgba(150, 150, 150, 0.35);
}
.element__borderColorSecondaryAccent {
  border-color: rgba(0, 0, 150, 0.1);
}
.element__borderColorModalHeader {
  border-color: #4bccd4;
}
.element__borderColorBlack {
  border-color: #111111;
}
.element__borderColorWhite {
  border-color: white;
}
.element__borderColorInherit {
  border-color: inherit;
}
.element__borderColorTransparent {
  border-color: transparent;
}
.element__borderColorGrey10 {
  border-color: rgba(245, 245, 245, 0.8);
}
.element__borderColorGrey20 {
  border-color: rgba(225, 225, 225, 0.8);
}
.element__borderColorGrey30 {
  border-color: rgba(205, 205, 205, 0.8);
}
.element__borderColorGrey40 {
  border-color: rgba(185, 185, 185, 0.8);
}
.element__borderColorGrey50 {
  border-color: rgba(165, 165, 165, 0.8);
}
.element__borderColorGrey60 {
  border-color: rgba(145, 145, 145, 0.8);
}
.element__borderColorGrey70 {
  border-color: rgba(125, 125, 125, 0.8);
}
.element__borderColorGrey80 {
  border-color: rgba(105, 105, 105, 0.8);
}
.element__borderColorGrey90 {
  border-color: rgba(85, 85, 85, 0.8);
}
.element__borderColorGrey100 {
  border-color: rgba(65, 65, 65, 0.8);
}
.element__width3xs {
  width: 16px;
}
.element__minWidth3xs {
  min-width: 16px;
}
.element__maxWidth3xs {
  max-width: 16px;
}
.element__widthXxs {
  width: 32px;
}
.element__minWidthXxs {
  min-width: 32px;
}
.element__maxWidthXxs {
  max-width: 32px;
}
.element__widthXs {
  width: 64px;
}
.element__minWidthXs {
  min-width: 64px;
}
.element__maxWidthXs {
  max-width: 64px;
}
.element__widthSm {
  width: 128px;
}
.element__minWidthSm {
  min-width: 128px;
}
.element__maxWidthSm {
  max-width: 128px;
}
.element__widthMd {
  width: 256px;
}
.element__minWidthMd {
  min-width: 256px;
}
.element__maxWidthMd {
  max-width: 256px;
}
.element__widthLg {
  width: 512px;
}
.element__minWidthLg {
  min-width: 512px;
}
.element__maxWidthLg {
  max-width: 512px;
}
.element__widthXlg {
  width: 1024px;
}
.element__minWidthXlg {
  min-width: 1024px;
}
.element__maxWidthXlg {
  max-width: 1024px;
}
.element__height3xs {
  height: 16px;
}
.element__minHeight3xs {
  min-height: 16px;
}
.element__maxHeight3xs {
  max-height: 16px;
}
.element__heightXxs {
  height: 32px;
}
.element__minHeightXxs {
  min-height: 32px;
}
.element__maxHeightXxs {
  max-height: 32px;
}
.element__heightXs {
  height: 64px;
}
.element__minHeightXs {
  min-height: 64px;
}
.element__maxHeightXs {
  max-height: 64px;
}
.element__heightSm {
  height: 128px;
}
.element__minHeightSm {
  min-height: 128px;
}
.element__maxHeightSm {
  max-height: 128px;
}
.element__heightMd {
  height: 256px;
}
.element__minHeightMd {
  min-height: 256px;
}
.element__maxHeightMd {
  max-height: 256px;
}
.element__heightLg {
  height: 512px;
}
.element__minHeightLg {
  min-height: 512px;
}
.element__maxHeightLg {
  max-height: 512px;
}
.element__heightXlg {
  height: 1024px;
}
.element__minHeightXlg {
  min-height: 1024px;
}
.element__maxHeightXlg {
  max-height: 1024px;
}
.element__height3xsOrViewport {
  height: min(100vh - 64px, 16px);
}
.element__maxHeight3xsOrViewport {
  max-height: min(100vh - 64px, 16px);
}
.element__heightXxsOrViewport {
  height: min(100vh - 64px, 32px);
}
.element__maxHeightXxsOrViewport {
  max-height: min(100vh - 64px, 32px);
}
.element__heightXsOrViewport {
  height: min(100vh - 64px, 64px);
}
.element__maxHeightXsOrViewport {
  max-height: min(100vh - 64px, 64px);
}
.element__heightSmOrViewport {
  height: min(100vh - 64px, 128px);
}
.element__maxHeightSmOrViewport {
  max-height: min(100vh - 64px, 128px);
}
.element__heightMdOrViewport {
  height: min(100vh - 64px, 256px);
}
.element__maxHeightMdOrViewport {
  max-height: min(100vh - 64px, 256px);
}
.element__heightLgOrViewport {
  height: min(100vh - 64px, 512px);
}
.element__maxHeightLgOrViewport {
  max-height: min(100vh - 64px, 512px);
}
.element__heightXlgOrViewport {
  height: min(100vh - 64px, 1024px);
}
.element__maxHeightXlgOrViewport {
  max-height: min(100vh - 64px, 1024px);
}

.hoverParent {
  position: relative;
}

.hoverParent:hover.hoverParent_hoverChild__showOverflow,
.hoverParent:hover .hoverParent_hoverChild__showOverflow {
  overflow: visible;
  width: 256px;
}

.hoverParent:hover.hoverParent_typography__multiline,
.hoverParent:hover .hoverParent_typography__multiline {
  text-overflow: unset;
  overflow: visible;
  white-space: normal;
}

.hoverParent:hover.hoverParent_hoverChild__hidden,
.hoverParent:hover .hoverParent_hoverChild__hidden {
  display: block;
}

.hoverParent:hover.hoverParent_hoverChild__hidden.element__absolute,
.hoverParent:hover .hoverParent_hoverChild__hidden.element__absolute {
  position: absolute;
}

.hoverParent.hoverParent_hoverChild__hidden,
.hoverParent .hoverParent_hoverChild__hidden {
  display: none;
}

.hoverParent:hover.hoverParent_hoverChild__shown,
.hoverParent:hover .hoverParent_hoverChild__shown {
  display: none;
}

.hoverParent.hoverParent_hoverChild__shown,
.hoverParent .hoverParent_hoverChild__shown {
  display: block;
}

.hoverParent.hoverParent_hoverChild__shown.element__absolute,
.hoverParent .hoverParent_hoverChild__shown.element__absolute {
  position: absolute;
  display: block;
}