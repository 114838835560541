@mixin borderRadiusSizeForDirection(
  $verticalDirection,
  $horizontalDirection,
  $sizeCategory,
  $size
) {
  .element_borderRadius__#{$verticalDirection}#{capitalize($horizontalDirection)}#{capitalize($sizeCategory)} {
    border-#{$verticalDirection}-#{$horizontalDirection}-radius: $size;
  }
}

@mixin borderRadiusForDirection(
  $verticalDirection,
  $horizontalDirection,
  $sizes
) {
  @each $size in $sizes {
    @include borderRadiusSizeForDirection(
      $verticalDirection,
      $horizontalDirection,
      map-get($size, name),
      map-get($size, size)
    );
  }
}

$borderRadiusSizes: (
  (
    name: 'zero',
    size: 0,
  ),
  (
    name: 'xs',
    size: 2px,
  ),
  (
    name: 'sm',
    size: 4px,
  ),
  (
    name: 'md',
    size: 8px,
  ),
  (
    name: 'lg',
    size: 12px,
  ),
  (
    name: 'rounded',
    size: 999px,
  ),
  (
    name: 'elliptical',
    size: 50%,
  )
);

@include borderRadiusForDirection('top', 'left', $borderRadiusSizes);
@include borderRadiusForDirection('top', 'right', $borderRadiusSizes);
@include borderRadiusForDirection('bottom', 'right', $borderRadiusSizes);
@include borderRadiusForDirection('bottom', 'left', $borderRadiusSizes);
