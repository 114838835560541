@import '../../../styles/colors';
@import '../../../styles/baseline';

.table {
  &__striped tbody &_row:nth-child(odd) {
    background-color: color(grey10);
  }

  &_table {
    width: 100%;
    border: 0;
    border-collapse: collapse;
    table-layout: fixed;
  }

  &_cell {
    word-wrap: break-word;

    &__verticalAlignToBottom {
      vertical-align: bottom;
    }
  }

  &_stickyHeader {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }

  &_stickyFooter {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
}
