@import '../../../styles/capitalize';
@import '../../../styles/colors';

@mixin elementBorder() {
  &__border {
    border-style: solid;
  }

  &__borderSizeZero {
    border-width: 0;
  }

  &__borderSizeSm {
    border-width: 1px;
  }

  &__borderSizeMd {
    border-width: 2px;
  }

  &__borderSizeLg {
    border-width: 3px;
  }

  &__borderSizeXlg {
    border-width: 4px;
  }

  @each $colorName, $color in $colors {
    &__borderColor#{capitalize('#{$colorName}')} {
      border-color: $color;
    }
  }
}
