@import '../../../../../../node_modules/shared-between-front-ends/src/styles/baseline';
@import '../../../../../../node_modules/shared-between-front-ends/src/styles/colors';

.teamCard {
  width: 30 * $baseline;

  &_link {
    transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
    border: 2px solid color(primary);

    &:hover {
      transform: scale(1.005);
    }
  }
}
