@import '../../../styles/baseline';
@import '../../../styles/colors';
@import '../../../styles/breakpoints';

.marginSpace {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include from('xs') {
    max-width: calc(100vw - 68px + (3 * #{$baseline}));
  }

  @include from('sm') {
    max-width: calc(100vw - (9 * #{$baseline}) + (3 * #{$baseline}));
  }

  @include from('lg') {
    max-width: calc(100vw - (24 * #{$baseline}) + (3 * #{$baseline}));
  }

  @include from('xlg') {
    max-width: calc(100vw - (27 * #{$baseline}) + (3 * #{$baseline}));
  }

  @include from('xxlg') {
    max-width: calc((213 * #{$baseline}) + (3 * #{$baseline}));
  }

  &__compact {
    @include from('xs') {
      max-width: calc(100vw - 68px + (3 * #{$baseline}));
    }

    @include from('sm') {
      max-width: calc(100vw - (9 * #{$baseline}) + (3 * #{$baseline}));
    }

    @include from('md') {
      max-width: calc((80 * #{$baseline}) + (3 * #{$baseline}));
    }
  }

  &__noPaddingForSmallerThanSm {
    @include to('xs') {
      max-width: none;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__noPaddingForSmallerThanMd {
    @include to('sm') {
      max-width: none;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
