.checkbox_hiddenCheckbox {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}
.checkbox_fakeCheckbox {
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  border: 2px solid #111111;
}
.checkbox_hiddenCheckbox:focus + .checkbox_fakeCheckbox {
  outline: 5px solid Highlight;
  outline: 5px solid -webkit-focus-ring-color;
  outline-offset: -3px;
}