@import '../../../styles/colors';
@import '../../../styles/baseline';
@import '../../../styles/breakpoints';

.button {
  transition: all 0.2s;

  &__primary {
    background-color: color(primary);
    color: color(white);
    border-color: color(primary);

    &:hover {
      background-color: lighten(color(primary), 5%);
    }

    &:active {
      background-color: color(white);
      color: color(primary);
      border-color: color(primary);
    }

    &.button__disabled {
      background-color: color(grey20);
      color: color(grey60);
      border-color: color(grey60);
    }
  }

  &__secondary {
    background-color: color(white);
    color: color(action);
    border-color: color(action);

    &:hover {
      $lighterColor: lighten(color(action), 15%);

      border-color: $lighterColor;
      color: $lighterColor;
    }

    &:active {
      background-color: color(primary);
      color: color(white);
      border-color: transparent;
    }

    &.button__disabled {
      background-color: color(grey20);
      color: color(grey60);
    }
  }

  &__additional {
    background-color: color(white);
    color: color(action);
    border-color: color(action);

    &:hover {
      border-color: color(actionHover);
      color: color(actionHover);
    }

    &:active {
      background-color: color(action);
      color: color(white);
      border-color: transparent;
    }

    &.button__disabled {
      background-color: color(grey20);
      color: color(grey60);
    }
  }

  &__navigation {
    background-color: color(transparent);
    color: color(fontColor);

    &:hover {
      color: color(fontColor);
      background-color: color(transparent);
    }

    &.button__disabled {
      background-color: color(transparent);
      text-decoration: line-through;
      cursor: not-allowed;
    }
  }
}
