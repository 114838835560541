@import 'Gutter-variables';
@import '../../../styles/spacing';

.gutter {
  min-height: $gutterThickness;
  min-width: $gutterThickness;

  &__half {
    $halfGutterThickness: calc($gutterThickness / 2);
    min-height: $halfGutterThickness;
    min-width: $halfGutterThickness;
  }

  &__quarter {
    $quarterGutterThickness: calc($gutterThickness / 4);
    min-height: $quarterGutterThickness;
    min-width: $quarterGutterThickness;
  }
}

@mixin responsiveGutterSize(
  $sizeCategory,
  $xsSize,
  $smSize,
  $mdSize,
  $lgSize,
  $xlgSize,
  $xxlgSize
) {
  .gutter__size#{capitalize($sizeCategory)} {
    min-width: $xsSize;
    min-height: $xsSize;

    @include from('sm') {
      min-width: $smSize;
      min-height: $smSize;
    }

    @include from('md') {
      min-width: $mdSize;
      min-height: $mdSize;
    }

    @include from('lg') {
      min-width: $lgSize;
      min-height: $lgSize;
    }

    @include from('xlg') {
      min-width: $xlgSize;
      min-height: $xlgSize;
    }

    @include from('xxlg') {
      min-width: $xxlgSize;
      min-height: $xxlgSize;
    }
  }
}

@include responsiveGutterSize('zero', $responsiveSpacingsForZero...);
@include responsiveGutterSize('3xs', $responsiveSpacingsFor3xs...);
@include responsiveGutterSize('xxs', $responsiveSpacingsForXxs...);
@include responsiveGutterSize('xs', $responsiveSpacingsForXs...);
@include responsiveGutterSize('sm', $responsiveSpacingsForSm...);
@include responsiveGutterSize('md', $responsiveSpacingsForMd...);
@include responsiveGutterSize('lg', $responsiveSpacingsForLg...);
@include responsiveGutterSize('xlg', $responsiveSpacingsForXlg...);
@include responsiveGutterSize('xxlg', $responsiveSpacingsForXxlg...);
