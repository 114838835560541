@import '../../../styles/breakpoints';

.hidden {
  display: none !important;

  &__thinnerThanXs {
    @include to('xxs') {
      display: none !important;
    }
  }

  &__thinnerThanSm {
    @include to('xs') {
      display: none !important;
    }
  }

  &__thinnerThanMd {
    @include to('sm') {
      display: none !important;
    }
  }

  &__thinnerThanLg {
    @include to('md') {
      display: none !important;
    }
  }

  &__thinnerThanXlg {
    @include to('lg') {
      display: none !important;
    }
  }

  &__thinnerThanXxlg {
    @include to('xlg') {
      display: none !important;
    }
  }

  &__widerThanXxs {
    @include from('xs') {
      display: none !important;
    }
  }

  &__widerThanXs {
    @include from('sm') {
      display: none !important;
    }
  }

  &__widerThanSm {
    @include from('md') {
      display: none !important;
    }
  }

  &__widerThanMd {
    @include from('lg') {
      display: none !important;
    }
  }

  &__widerThanLg {
    @include from('xlg') {
      display: none !important;
    }
  }

  &__widerThanXlg {
    @include from('xxlg') {
      display: none !important;
    }
  }

  &__atXxs {
    @include at('xxs') {
      display: none !important;
    }
  }

  &__atXs {
    @include at('xs') {
      display: none !important;
    }
  }

  &__atSm {
    @include at('sm') {
      display: none !important;
    }
  }

  &__atMd {
    @include at('md') {
      display: none !important;
    }
  }

  &__atLg {
    @include at('lg') {
      display: none !important;
    }
  }

  &__atXlg {
    @include at('xlg') {
      display: none !important;
    }
  }
}

.visuallyHidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
