@import '../../../styles/colors';
@import '../../../styles/spacing';
@import '../../../styles/breakpoints';

.tileBag {
  &_tile {
    position: relative;
    min-width: 0;
  }

  &_tabletChunk {
    min-width: 0;
  }

  @include from('sm') {
    &_tile {
      flex-basis: 50%;
      width: 50%;
    }

    &_tabletChunk {
      display: flex;
      flex-basis: 50%;
      width: 100%;
    }
  }

  @include from('lg') {
    &_tabletChunk {
      width: 50%;
    }
  }

  @include from('lg') {
    &_desktopChunk {
      display: flex;
    }
  }
}
