@import '../../../styles/colors';
@import '../../../styles/baseline';

.checkbox {
  &_hiddenCheckbox {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
  }

  &_fakeCheckbox {
    $boxSize: 3 * $baseline;
    min-width: $boxSize;
    min-height: $boxSize;
    max-width: $boxSize;
    max-height: $boxSize;
    border: 2px solid color(black);
  }

  &_hiddenCheckbox:focus + &_fakeCheckbox {
    //noinspection CssOverwrittenProperties
    outline: 5px solid Highlight;

    //noinspection CssOverwrittenProperties
    outline: 5px solid -webkit-focus-ring-color;

    outline-offset: -3px;
  }
}
