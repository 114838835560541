.marginSpace {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 481px) {
  .marginSpace {
    max-width: calc(100vw - 68px + 3 * 8px);
  }
}
@media (min-width: 601px) {
  .marginSpace {
    max-width: calc(100vw - 9 * 8px + 3 * 8px);
  }
}
@media (min-width: 1081px) {
  .marginSpace {
    max-width: calc(100vw - 24 * 8px + 3 * 8px);
  }
}
@media (min-width: 1441px) {
  .marginSpace {
    max-width: calc(100vw - 27 * 8px + 3 * 8px);
  }
}
@media (min-width: 1921px) {
  .marginSpace {
    max-width: calc(213 * 8px + 3 * 8px);
  }
}
@media (min-width: 481px) {
  .marginSpace__compact {
    max-width: calc(100vw - 68px + 3 * 8px);
  }
}
@media (min-width: 601px) {
  .marginSpace__compact {
    max-width: calc(100vw - 9 * 8px + 3 * 8px);
  }
}
@media (min-width: 841px) {
  .marginSpace__compact {
    max-width: calc(80 * 8px + 3 * 8px);
  }
}
@media (max-width: 600px) {
  .marginSpace__noPaddingForSmallerThanSm {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 840px) {
  .marginSpace__noPaddingForSmallerThanMd {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}