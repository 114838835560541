.appointmentBox {
  position: relative;
}
.appointmentBox::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.appointmentBox__highlighted::before {
  opacity: 1;
}