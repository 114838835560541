.icon {
  line-height: 0;
}
.icon__sizeXs {
  font-size: 8px;
}
.icon__sizeSm {
  font-size: 16px;
}
.icon__sizeMd {
  font-size: 24px;
}
.icon__sizeLg {
  font-size: 32px;
}