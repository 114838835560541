@import '../../../styles/spacing';

@include spacings('margin', 'minusSm', $responsiveSpacingsForMinusSm...);
@include spacings('margin', 'zero', $responsiveSpacingsForZero...);
@include spacings('margin', '3xs', $responsiveSpacingsFor3xs...);
@include spacings('margin', 'xxs', $responsiveSpacingsForXxs...);
@include spacings('margin', 'xs', $responsiveSpacingsForXs...);
@include spacings('margin', 'sm', $responsiveSpacingsForSm...);
@include spacings('margin', 'md', $responsiveSpacingsForMd...);
@include spacings('margin', 'lg', $responsiveSpacingsForLg...);
@include spacings('margin', 'xlg', $responsiveSpacingsForXlg...);
@include spacings('margin', 'xxlg', $responsiveSpacingsForXxlg...);

.margin__lastChildSizeZeroBottom:last-child {
  margin-bottom: 0;
}
