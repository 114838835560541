@import '../../../styles/baseline';
@import '../../../styles/capitalize';

$widths: (
  3xs: 2 * $baseline,
  xxs: 4 * $baseline,
  xs: 8 * $baseline,
  sm: 16 * $baseline,
  md: 32 * $baseline,
  lg: 64 * $baseline,
  xlg: 128 * $baseline,
);

@mixin elementWidths() {
  @each $widthName, $widthValue in $widths {
    &__width#{capitalize('#{$widthName}')} {
      width: $widthValue;
    }

    &__minWidth#{capitalize('#{$widthName}')} {
      min-width: $widthValue;
    }

    &__maxWidth#{capitalize('#{$widthName}')} {
      max-width: $widthValue;
    }
  }
}
