@import '../../../styles/colors';
@import '../../../styles/baseline';
@import '../../../styles/breakpoints';

.selectionInput {
  appearance: none;

  &_icon {
    pointer-events: none;
  }
}
