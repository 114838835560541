@import '../../../../../node_modules/shared-between-front-ends/src/styles/colors';

.resourceRow {
  &_dropTarget__above {
    border-top: 2px solid color(action);
  }

  &_dropTarget__below {
    border-bottom: 2px solid color(action);
  }
}
