@import '../../../node_modules/shared-between-front-ends/src/styles/baseline';

.logo {
  height: 6 * $baseline;
}

.bottomBorder {
  border-bottom: 1px solid;
  color: #e8e8e4;
}
