.button {
  transition: all 0.2s;
}
.button__primary {
  background-color: #243e90;
  color: white;
  border-color: #243e90;
}
.button__primary:hover {
  background-color: rgb(41.1, 70.7833333333, 164.4);
}
.button__primary:active {
  background-color: white;
  color: #243e90;
  border-color: #243e90;
}
.button__primary.button__disabled {
  background-color: rgba(225, 225, 225, 0.8);
  color: rgba(145, 145, 145, 0.8);
  border-color: rgba(145, 145, 145, 0.8);
}
.button__secondary {
  background-color: white;
  color: #f89838;
  border-color: #f89838;
}
.button__secondary:hover {
  border-color: rgb(250.5995145631, 190.25, 129.9004854369);
  color: rgb(250.5995145631, 190.25, 129.9004854369);
}
.button__secondary:active {
  background-color: #243e90;
  color: white;
  border-color: transparent;
}
.button__secondary.button__disabled {
  background-color: rgba(225, 225, 225, 0.8);
  color: rgba(145, 145, 145, 0.8);
}
.button__additional {
  background-color: white;
  color: #f89838;
  border-color: #f89838;
}
.button__additional:hover {
  border-color: #fab269;
  color: #fab269;
}
.button__additional:active {
  background-color: #f89838;
  color: white;
  border-color: transparent;
}
.button__additional.button__disabled {
  background-color: rgba(225, 225, 225, 0.8);
  color: rgba(145, 145, 145, 0.8);
}
.button__navigation {
  background-color: transparent;
  color: #333333;
}
.button__navigation:hover {
  color: #333333;
  background-color: transparent;
}
.button__navigation.button__disabled {
  background-color: transparent;
  text-decoration: line-through;
  cursor: not-allowed;
}