@import 'baseline';

@mixin primary() {
  font-family: 'Barlow', Arial, sans-serif;
}

$typographySizes: (
  (
    name: 'lg',
    sizesFromBreakpoint: (
      (
        breakpoint: 'xxs',
        fontSize: 10 * $typographyBaseline,
        lineHeight: 12 * $typographyBaseline,
      ),
      (
        breakpoint: 'xs',
        fontSize: 10 * $typographyBaseline,
        lineHeight: 12 * $typographyBaseline,
      ),
    ),
  ),
  (
    name: 'md',
    sizesFromBreakpoint: (
      (
        breakpoint: 'xxs',
        fontSize: 8 * $typographyBaseline,
        lineHeight: 10 * $typographyBaseline,
      ),
      (
        breakpoint: 'xs',
        fontSize: 8 * $typographyBaseline,
        lineHeight: 10 * $typographyBaseline,
      ),
    ),
  ),
  (
    name: 'sm',
    sizesFromBreakpoint: (
      (
        breakpoint: 'xxs',
        fontSize: 6 * $typographyBaseline,
        lineHeight: 8 * $typographyBaseline,
      ),
      (
        breakpoint: 'xs',
        fontSize: 6 * $typographyBaseline,
        lineHeight: 8 * $typographyBaseline,
      ),
    ),
  ),
  (
    name: 'xs',
    sizesFromBreakpoint: (
      (
        breakpoint: 'xxs',
        fontSize: 5 * $typographyBaseline,
        lineHeight: 7 * $typographyBaseline,
      ),
      (
        breakpoint: 'xs',
        fontSize: 5 * $typographyBaseline,
        lineHeight: 7 * $typographyBaseline,
      ),
    ),
  ),
  (
    name: 'xxs',
    sizesFromBreakpoint: (
      (
        breakpoint: 'xxs',
        fontSize: 4 * $typographyBaseline,
        lineHeight: 6 * $typographyBaseline,
      ),
      (
        breakpoint: 'xs',
        fontSize: 4 * $typographyBaseline,
        lineHeight: 6 * $typographyBaseline,
      ),
    ),
  ),
  (
    name: '3xs',
    sizesFromBreakpoint: (
      (
        breakpoint: 'xxs',
        fontSize: 3.5 * $typographyBaseline,
        lineHeight: 5 * $typographyBaseline,
      ),
      (
        breakpoint: 'xs',
        fontSize: 3.5 * $typographyBaseline,
        lineHeight: 5 * $typographyBaseline,
      ),
    ),
  ),
  (
    name: '4xs',
    sizesFromBreakpoint: (
      (
        breakpoint: 'xxs',
        fontSize: 3 * $typographyBaseline,
        lineHeight: 5 * $typographyBaseline,
      ),
      (
        breakpoint: 'xs',
        fontSize: 3 * $typographyBaseline,
        lineHeight: 5 * $typographyBaseline,
      ),
    ),
  )
);
