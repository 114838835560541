.gutterBag__gutterSizeMinusSm {
  margin: 16px;
}
.gutterBag__gutterSizeMinusSm > .gutterBag_item {
  margin: -16px;
}
.gutterBag__gutterSizeZero {
  margin: 0;
}
.gutterBag__gutterSizeZero > .gutterBag_item {
  margin: 0;
}
.gutterBag__gutterSize3xs {
  margin: -2px;
}
.gutterBag__gutterSize3xs > .gutterBag_item {
  margin: 2px;
}
.gutterBag__gutterSizeXxs {
  margin: -4px;
}
.gutterBag__gutterSizeXxs > .gutterBag_item {
  margin: 4px;
}
.gutterBag__gutterSizeXs {
  margin: -8px;
}
.gutterBag__gutterSizeXs > .gutterBag_item {
  margin: 8px;
}
.gutterBag__gutterSizeSm {
  margin: -16px;
}
.gutterBag__gutterSizeSm > .gutterBag_item {
  margin: 16px;
}
.gutterBag__gutterSizeMd {
  margin: -24px;
}
.gutterBag__gutterSizeMd > .gutterBag_item {
  margin: 24px;
}
.gutterBag__gutterSizeLg {
  margin: -32px;
}
.gutterBag__gutterSizeLg > .gutterBag_item {
  margin: 32px;
}
.gutterBag__gutterSizeXlg {
  margin: -48px;
}
.gutterBag__gutterSizeXlg > .gutterBag_item {
  margin: 48px;
}
.gutterBag__gutterSizeXxlg {
  margin: -96px;
}
.gutterBag__gutterSizeXxlg > .gutterBag_item {
  margin: 96px;
}