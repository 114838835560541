.table__striped tbody .table_row:nth-child(odd) {
  background-color: rgba(245, 245, 245, 0.8);
}
.table_table {
  width: 100%;
  border: 0;
  border-collapse: collapse;
  table-layout: fixed;
}
.table_cell {
  word-wrap: break-word;
}
.table_cell__verticalAlignToBottom {
  vertical-align: bottom;
}
.table_stickyHeader {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.table_stickyFooter {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}