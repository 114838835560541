.radioButtonGroup_button {
  position: relative;
  cursor: pointer;
}
.radioButtonGroup_button__readOnly {
  pointer-events: none;
  cursor: default;
}
.radioButtonGroup_hiddenButton {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}
.radioButtonGroup_fakeButton {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #111111;
}
.radioButtonGroup_button__readOnly .radioButtonGroup_fakeButton {
  border-color: #111111;
  background-color: rgba(225, 225, 225, 0.8);
}
.radioButtonGroup_fakeButtonIcon {
  height: 16px;
  width: 16px;
}
.radioButtonGroup_hiddenButton:focus + .radioButtonGroup_fakeButton {
  outline: 5px solid rgba(225, 225, 225, 0.8);
  outline: 5px solid -webkit-focus-ring-color;
}
.radioButtonGroup_button__readOnly .radioButtonGroup_buttonLabel {
  pointer-events: none;
}