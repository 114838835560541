@import '../../../styles/spacing';
@import '../../../styles/capitalize';

.gutterBag {
  @each $spacingName, $spacingSize in $spacings {
    $capitalizedSpacingName: capitalize('#{$spacingName}');
    $classNameForGutterSize: 'gutterSize#{$capitalizedSpacingName}';

    $gutterHalved: calc($spacingSize / 2);

    &__#{$classNameForGutterSize} {
      margin: -$spacingSize;
    }

    &__#{$classNameForGutterSize} > &_item {
      margin: $spacingSize;
    }
  }
}
