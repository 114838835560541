.hidden {
  display: none !important;
}
@media (max-width: 480px) {
  .hidden__thinnerThanXs {
    display: none !important;
  }
}
@media (max-width: 600px) {
  .hidden__thinnerThanSm {
    display: none !important;
  }
}
@media (max-width: 840px) {
  .hidden__thinnerThanMd {
    display: none !important;
  }
}
@media (max-width: 1080px) {
  .hidden__thinnerThanLg {
    display: none !important;
  }
}
@media (max-width: 1440px) {
  .hidden__thinnerThanXlg {
    display: none !important;
  }
}
@media (max-width: 1920px) {
  .hidden__thinnerThanXxlg {
    display: none !important;
  }
}
@media (min-width: 481px) {
  .hidden__widerThanXxs {
    display: none !important;
  }
}
@media (min-width: 601px) {
  .hidden__widerThanXs {
    display: none !important;
  }
}
@media (min-width: 841px) {
  .hidden__widerThanSm {
    display: none !important;
  }
}
@media (min-width: 1081px) {
  .hidden__widerThanMd {
    display: none !important;
  }
}
@media (min-width: 1441px) {
  .hidden__widerThanLg {
    display: none !important;
  }
}
@media (min-width: 1921px) {
  .hidden__widerThanXlg {
    display: none !important;
  }
}
@media (max-width: 480px) {
  .hidden__atXxs {
    display: none !important;
  }
}
@media (min-width: 481px) and (max-width: 600px) {
  .hidden__atXs {
    display: none !important;
  }
}
@media (min-width: 601px) and (max-width: 840px) {
  .hidden__atSm {
    display: none !important;
  }
}
@media (min-width: 841px) and (max-width: 1080px) {
  .hidden__atMd {
    display: none !important;
  }
}
@media (min-width: 1081px) and (max-width: 1440px) {
  .hidden__atLg {
    display: none !important;
  }
}
@media (min-width: 1441px) and (max-width: 1920px) {
  .hidden__atXlg {
    display: none !important;
  }
}

.visuallyHidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}