.flex {
  display: flex;
  flex-direction: row;
}
.flex__occupyVerticalSpace {
  height: 100%;
}
.flex__centeredVertically {
  align-items: center;
}
.flex__centeredHorizontally {
  justify-content: center;
}
.flex__alignTop {
  align-items: flex-start;
}
.flex__alignBottom {
  align-items: flex-end;
}
.flex__alignBaseline {
  align-items: baseline;
}
.flex__alignRight {
  justify-content: flex-end;
}
.flex__spaceBetween {
  justify-content: space-between;
}
.flex__wrapContent {
  flex-wrap: wrap;
}
.flex__stretchToSameHeight {
  align-items: stretch;
}
.flex__reverseDirection {
  flex-direction: row-reverse;
}
.flex__inlineFlex {
  display: inline-flex;
}
@media (max-width: 600px) {
  .flex__pileVerticallyToXsScreen {
    flex-direction: column;
  }
}
@media (max-width: 840px) {
  .flex__pileVerticallyToSmScreen {
    flex-direction: column;
  }
}
@media (max-width: 1080px) {
  .flex__pileVerticallyToMdScreen {
    flex-direction: column;
  }
}
@media (max-width: 1440px) {
  .flex__pileVerticallyToLgScreen {
    flex-direction: column;
  }
}
.flex__pileVertically {
  flex-direction: column;
}
.flex_flexItem {
  min-width: 1px;
  max-width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
}
@media (max-width: 600px) {
  .flex__pileVerticallyToXsScreen > .flex_flexItem {
    width: 100%;
    flex: none;
  }
}
@media (max-width: 840px) {
  .flex__pileVerticallyToSmScreen > .flex_flexItem {
    width: 100%;
    flex: none;
  }
}
@media (max-width: 1080px) {
  .flex__pileVerticallyToMdScreen > .flex_flexItem {
    width: 100%;
    flex: none;
  }
}
@media (max-width: 1440px) {
  .flex__pileVerticallyToLgScreen > .flex_flexItem {
    width: 100%;
    flex: none;
  }
}