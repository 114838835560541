.color__primary {
  color: #243e90;
}

.color__primaryHoverable:hover, .color__primaryHoverable:active {
  color: #243e90;
}

.color_background__primary {
  background-color: #243e90;
}

.color_background__primaryHoverable:hover, .color_background__primaryHoverable:active {
  background-color: #243e90;
}

.color__secondary {
  color: darkGreen;
}

.color__secondaryHoverable:hover, .color__secondaryHoverable:active {
  color: darkGreen;
}

.color_background__secondary {
  background-color: darkGreen;
}

.color_background__secondaryHoverable:hover, .color_background__secondaryHoverable:active {
  background-color: darkGreen;
}

.color__lightSecondary {
  color: rgba(178, 136, 19, 0.1);
}

.color__lightSecondaryHoverable:hover, .color__lightSecondaryHoverable:active {
  color: rgba(178, 136, 19, 0.1);
}

.color_background__lightSecondary {
  background-color: rgba(178, 136, 19, 0.1);
}

.color_background__lightSecondaryHoverable:hover, .color_background__lightSecondaryHoverable:active {
  background-color: rgba(178, 136, 19, 0.1);
}

.color__error {
  color: red;
}

.color__errorHoverable:hover, .color__errorHoverable:active {
  color: red;
}

.color_background__error {
  background-color: red;
}

.color_background__errorHoverable:hover, .color_background__errorHoverable:active {
  background-color: red;
}

.color__lightError {
  color: rgba(162, 42, 21, 0.1);
}

.color__lightErrorHoverable:hover, .color__lightErrorHoverable:active {
  color: rgba(162, 42, 21, 0.1);
}

.color_background__lightError {
  background-color: rgba(162, 42, 21, 0.1);
}

.color_background__lightErrorHoverable:hover, .color_background__lightErrorHoverable:active {
  background-color: rgba(162, 42, 21, 0.1);
}

.color__danger {
  color: red;
}

.color__dangerHoverable:hover, .color__dangerHoverable:active {
  color: red;
}

.color_background__danger {
  background-color: red;
}

.color_background__dangerHoverable:hover, .color_background__dangerHoverable:active {
  background-color: red;
}

.color__success {
  color: green;
}

.color__successHoverable:hover, .color__successHoverable:active {
  color: green;
}

.color_background__success {
  background-color: green;
}

.color_background__successHoverable:hover, .color_background__successHoverable:active {
  background-color: green;
}

.color__lightGrey {
  color: #f7f7f7;
}

.color__lightGreyHoverable:hover, .color__lightGreyHoverable:active {
  color: #f7f7f7;
}

.color_background__lightGrey {
  background-color: #f7f7f7;
}

.color_background__lightGreyHoverable:hover, .color_background__lightGreyHoverable:active {
  background-color: #f7f7f7;
}

.color__grey1 {
  color: #f7f7f7;
}

.color__grey1Hoverable:hover, .color__grey1Hoverable:active {
  color: #f7f7f7;
}

.color_background__grey1 {
  background-color: #f7f7f7;
}

.color_background__grey1Hoverable:hover, .color_background__grey1Hoverable:active {
  background-color: #f7f7f7;
}

.color__grey2 {
  color: #efefef;
}

.color__grey2Hoverable:hover, .color__grey2Hoverable:active {
  color: #efefef;
}

.color_background__grey2 {
  background-color: #efefef;
}

.color_background__grey2Hoverable:hover, .color_background__grey2Hoverable:active {
  background-color: #efefef;
}

.color__grey3 {
  color: #e7e7e7;
}

.color__grey3Hoverable:hover, .color__grey3Hoverable:active {
  color: #e7e7e7;
}

.color_background__grey3 {
  background-color: #e7e7e7;
}

.color_background__grey3Hoverable:hover, .color_background__grey3Hoverable:active {
  background-color: #e7e7e7;
}

.color__grey4 {
  color: #e1e1e1;
}

.color__grey4Hoverable:hover, .color__grey4Hoverable:active {
  color: #e1e1e1;
}

.color_background__grey4 {
  background-color: #e1e1e1;
}

.color_background__grey4Hoverable:hover, .color_background__grey4Hoverable:active {
  background-color: #e1e1e1;
}

.color__grey5 {
  color: #dbdbdb;
}

.color__grey5Hoverable:hover, .color__grey5Hoverable:active {
  color: #dbdbdb;
}

.color_background__grey5 {
  background-color: #dbdbdb;
}

.color_background__grey5Hoverable:hover, .color_background__grey5Hoverable:active {
  background-color: #dbdbdb;
}

.color__grey6 {
  color: #808080;
}

.color__grey6Hoverable:hover, .color__grey6Hoverable:active {
  color: #808080;
}

.color_background__grey6 {
  background-color: #808080;
}

.color_background__grey6Hoverable:hover, .color_background__grey6Hoverable:active {
  background-color: #808080;
}

.color__eltelGrey {
  color: #6c6c6c;
}

.color__eltelGreyHoverable:hover, .color__eltelGreyHoverable:active {
  color: #6c6c6c;
}

.color_background__eltelGrey {
  background-color: #6c6c6c;
}

.color_background__eltelGreyHoverable:hover, .color_background__eltelGreyHoverable:active {
  background-color: #6c6c6c;
}

.color__doneBackground {
  color: #c0cbc0;
}

.color__doneBackgroundHoverable:hover, .color__doneBackgroundHoverable:active {
  color: #c0cbc0;
}

.color_background__doneBackground {
  background-color: #c0cbc0;
}

.color_background__doneBackgroundHoverable:hover, .color_background__doneBackgroundHoverable:active {
  background-color: #c0cbc0;
}

.color__urgentBackground {
  color: #e79791;
}

.color__urgentBackgroundHoverable:hover, .color__urgentBackgroundHoverable:active {
  color: #e79791;
}

.color_background__urgentBackground {
  background-color: #e79791;
}

.color_background__urgentBackgroundHoverable:hover, .color_background__urgentBackgroundHoverable:active {
  background-color: #e79791;
}

.color__shroud {
  color: rgba(0, 0, 0, 0.5);
}

.color__shroudHoverable:hover, .color__shroudHoverable:active {
  color: rgba(0, 0, 0, 0.5);
}

.color_background__shroud {
  background-color: rgba(0, 0, 0, 0.5);
}

.color_background__shroudHoverable:hover, .color_background__shroudHoverable:active {
  background-color: rgba(0, 0, 0, 0.5);
}

.color__highlight {
  color: rgba(248, 152, 56, 0.25);
}

.color__highlightHoverable:hover, .color__highlightHoverable:active {
  color: rgba(248, 152, 56, 0.25);
}

.color_background__highlight {
  background-color: rgba(248, 152, 56, 0.25);
}

.color_background__highlightHoverable:hover, .color_background__highlightHoverable:active {
  background-color: rgba(248, 152, 56, 0.25);
}

.color__fontColor {
  color: #333333;
}

.color__fontColorHoverable:hover, .color__fontColorHoverable:active {
  color: #333333;
}

.color_background__fontColor {
  background-color: #333333;
}

.color_background__fontColorHoverable:hover, .color_background__fontColorHoverable:active {
  background-color: #333333;
}

.color__lightSuccess {
  color: #ecf7ec;
}

.color__lightSuccessHoverable:hover, .color__lightSuccessHoverable:active {
  color: #ecf7ec;
}

.color_background__lightSuccess {
  background-color: #ecf7ec;
}

.color_background__lightSuccessHoverable:hover, .color_background__lightSuccessHoverable:active {
  background-color: #ecf7ec;
}

.color__action {
  color: #f89838;
}

.color__actionHoverable:hover, .color__actionHoverable:active {
  color: #f89838;
}

.color_background__action {
  background-color: #f89838;
}

.color_background__actionHoverable:hover, .color_background__actionHoverable:active {
  background-color: #f89838;
}

.color__actionHover {
  color: #fab269;
}

.color__actionHoverHoverable:hover, .color__actionHoverHoverable:active {
  color: #fab269;
}

.color_background__actionHover {
  background-color: #fab269;
}

.color_background__actionHoverHoverable:hover, .color_background__actionHoverHoverable:active {
  background-color: #fab269;
}

.color__indicationGrey {
  color: #cecece;
}

.color__indicationGreyHoverable:hover, .color__indicationGreyHoverable:active {
  color: #cecece;
}

.color_background__indicationGrey {
  background-color: #cecece;
}

.color_background__indicationGreyHoverable:hover, .color_background__indicationGreyHoverable:active {
  background-color: #cecece;
}

.color__tableBorder {
  color: #b4b4b4;
}

.color__tableBorderHoverable:hover, .color__tableBorderHoverable:active {
  color: #b4b4b4;
}

.color_background__tableBorder {
  background-color: #b4b4b4;
}

.color_background__tableBorderHoverable:hover, .color_background__tableBorderHoverable:active {
  background-color: #b4b4b4;
}

.color__inputBorder {
  color: rgba(108, 108, 108, 0.3);
}

.color__inputBorderHoverable:hover, .color__inputBorderHoverable:active {
  color: rgba(108, 108, 108, 0.3);
}

.color_background__inputBorder {
  background-color: rgba(108, 108, 108, 0.3);
}

.color_background__inputBorderHoverable:hover, .color_background__inputBorderHoverable:active {
  background-color: rgba(108, 108, 108, 0.3);
}

.color__disabled {
  color: #e8e8e4;
}

.color__disabledHoverable:hover, .color__disabledHoverable:active {
  color: #e8e8e4;
}

.color_background__disabled {
  background-color: #e8e8e4;
}

.color_background__disabledHoverable:hover, .color_background__disabledHoverable:active {
  background-color: #e8e8e4;
}

.color__disabledText {
  color: #c2c2c2;
}

.color__disabledTextHoverable:hover, .color__disabledTextHoverable:active {
  color: #c2c2c2;
}

.color_background__disabledText {
  background-color: #c2c2c2;
}

.color_background__disabledTextHoverable:hover, .color_background__disabledTextHoverable:active {
  background-color: #c2c2c2;
}

.color__primaryAccent {
  color: rgba(150, 150, 150, 0.35);
}

.color__primaryAccentHoverable:hover, .color__primaryAccentHoverable:active {
  color: rgba(150, 150, 150, 0.35);
}

.color_background__primaryAccent {
  background-color: rgba(150, 150, 150, 0.35);
}

.color_background__primaryAccentHoverable:hover, .color_background__primaryAccentHoverable:active {
  background-color: rgba(150, 150, 150, 0.35);
}

.color__secondaryAccent {
  color: rgba(0, 0, 150, 0.1);
}

.color__secondaryAccentHoverable:hover, .color__secondaryAccentHoverable:active {
  color: rgba(0, 0, 150, 0.1);
}

.color_background__secondaryAccent {
  background-color: rgba(0, 0, 150, 0.1);
}

.color_background__secondaryAccentHoverable:hover, .color_background__secondaryAccentHoverable:active {
  background-color: rgba(0, 0, 150, 0.1);
}

.color__modalHeader {
  color: #4bccd4;
}

.color__modalHeaderHoverable:hover, .color__modalHeaderHoverable:active {
  color: #4bccd4;
}

.color_background__modalHeader {
  background-color: #4bccd4;
}

.color_background__modalHeaderHoverable:hover, .color_background__modalHeaderHoverable:active {
  background-color: #4bccd4;
}

.color__black {
  color: #111111;
}

.color__blackHoverable:hover, .color__blackHoverable:active {
  color: #111111;
}

.color_background__black {
  background-color: #111111;
}

.color_background__blackHoverable:hover, .color_background__blackHoverable:active {
  background-color: #111111;
}

.color__white {
  color: white;
}

.color__whiteHoverable:hover, .color__whiteHoverable:active {
  color: white;
}

.color_background__white {
  background-color: white;
}

.color_background__whiteHoverable:hover, .color_background__whiteHoverable:active {
  background-color: white;
}

.color__inherit {
  color: inherit;
}

.color__inheritHoverable:hover, .color__inheritHoverable:active {
  color: inherit;
}

.color_background__inherit {
  background-color: inherit;
}

.color_background__inheritHoverable:hover, .color_background__inheritHoverable:active {
  background-color: inherit;
}

.color__transparent {
  color: transparent;
}

.color__transparentHoverable:hover, .color__transparentHoverable:active {
  color: transparent;
}

.color_background__transparent {
  background-color: transparent;
}

.color_background__transparentHoverable:hover, .color_background__transparentHoverable:active {
  background-color: transparent;
}

.color__grey10 {
  color: rgba(245, 245, 245, 0.8);
}

.color__grey10Hoverable:hover, .color__grey10Hoverable:active {
  color: rgba(245, 245, 245, 0.8);
}

.color_background__grey10 {
  background-color: rgba(245, 245, 245, 0.8);
}

.color_background__grey10Hoverable:hover, .color_background__grey10Hoverable:active {
  background-color: rgba(245, 245, 245, 0.8);
}

.color__grey20 {
  color: rgba(225, 225, 225, 0.8);
}

.color__grey20Hoverable:hover, .color__grey20Hoverable:active {
  color: rgba(225, 225, 225, 0.8);
}

.color_background__grey20 {
  background-color: rgba(225, 225, 225, 0.8);
}

.color_background__grey20Hoverable:hover, .color_background__grey20Hoverable:active {
  background-color: rgba(225, 225, 225, 0.8);
}

.color__grey30 {
  color: rgba(205, 205, 205, 0.8);
}

.color__grey30Hoverable:hover, .color__grey30Hoverable:active {
  color: rgba(205, 205, 205, 0.8);
}

.color_background__grey30 {
  background-color: rgba(205, 205, 205, 0.8);
}

.color_background__grey30Hoverable:hover, .color_background__grey30Hoverable:active {
  background-color: rgba(205, 205, 205, 0.8);
}

.color__grey40 {
  color: rgba(185, 185, 185, 0.8);
}

.color__grey40Hoverable:hover, .color__grey40Hoverable:active {
  color: rgba(185, 185, 185, 0.8);
}

.color_background__grey40 {
  background-color: rgba(185, 185, 185, 0.8);
}

.color_background__grey40Hoverable:hover, .color_background__grey40Hoverable:active {
  background-color: rgba(185, 185, 185, 0.8);
}

.color__grey50 {
  color: rgba(165, 165, 165, 0.8);
}

.color__grey50Hoverable:hover, .color__grey50Hoverable:active {
  color: rgba(165, 165, 165, 0.8);
}

.color_background__grey50 {
  background-color: rgba(165, 165, 165, 0.8);
}

.color_background__grey50Hoverable:hover, .color_background__grey50Hoverable:active {
  background-color: rgba(165, 165, 165, 0.8);
}

.color__grey60 {
  color: rgba(145, 145, 145, 0.8);
}

.color__grey60Hoverable:hover, .color__grey60Hoverable:active {
  color: rgba(145, 145, 145, 0.8);
}

.color_background__grey60 {
  background-color: rgba(145, 145, 145, 0.8);
}

.color_background__grey60Hoverable:hover, .color_background__grey60Hoverable:active {
  background-color: rgba(145, 145, 145, 0.8);
}

.color__grey70 {
  color: rgba(125, 125, 125, 0.8);
}

.color__grey70Hoverable:hover, .color__grey70Hoverable:active {
  color: rgba(125, 125, 125, 0.8);
}

.color_background__grey70 {
  background-color: rgba(125, 125, 125, 0.8);
}

.color_background__grey70Hoverable:hover, .color_background__grey70Hoverable:active {
  background-color: rgba(125, 125, 125, 0.8);
}

.color__grey80 {
  color: rgba(105, 105, 105, 0.8);
}

.color__grey80Hoverable:hover, .color__grey80Hoverable:active {
  color: rgba(105, 105, 105, 0.8);
}

.color_background__grey80 {
  background-color: rgba(105, 105, 105, 0.8);
}

.color_background__grey80Hoverable:hover, .color_background__grey80Hoverable:active {
  background-color: rgba(105, 105, 105, 0.8);
}

.color__grey90 {
  color: rgba(85, 85, 85, 0.8);
}

.color__grey90Hoverable:hover, .color__grey90Hoverable:active {
  color: rgba(85, 85, 85, 0.8);
}

.color_background__grey90 {
  background-color: rgba(85, 85, 85, 0.8);
}

.color_background__grey90Hoverable:hover, .color_background__grey90Hoverable:active {
  background-color: rgba(85, 85, 85, 0.8);
}

.color__grey100 {
  color: rgba(65, 65, 65, 0.8);
}

.color__grey100Hoverable:hover, .color__grey100Hoverable:active {
  color: rgba(65, 65, 65, 0.8);
}

.color_background__grey100 {
  background-color: rgba(65, 65, 65, 0.8);
}

.color_background__grey100Hoverable:hover, .color_background__grey100Hoverable:active {
  background-color: rgba(65, 65, 65, 0.8);
}