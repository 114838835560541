@import '../../../styles/colors';

@mixin hoverableColor($colorName) {
  .color__#{'' + $colorName} {
    color: color($colorName);
  }

  .color__#{'' + $colorName}Hoverable {
    &:hover,
    &:active {
      color: color($colorName);
    }
  }
}

@mixin backgroundColor($colorName) {
  .color_background__#{'' + $colorName} {
    background-color: color($colorName);
  }

  .color_background__#{'' + $colorName}Hoverable {
    &:hover,
    &:active {
      background-color: color($colorName);
    }
  }
}

@mixin backgroundAndHoverableColor($colorName) {
  @include hoverableColor($colorName);
  @include backgroundColor($colorName);
}

@each $color in $colors {
  @include backgroundAndHoverableColor(nth($color, 1));
}
